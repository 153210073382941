import Image from 'next/image';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { IItem } from '@pickles/shared/models';
import { isEbidAuctionRunning } from '@pickles/shared/models/helpers';
import { itemActions, userActions } from '@pickles/shared/redux/actions';

import { AppHooks, ItemHooks, UserHooks } from '../../app/redux/hooks';
import useScreenSize from '../../hooks/useScreenSize';
import SuggestedCarsItem from '../Shared/SuggestedCarsItem';
import ShortDetail from './components/ShortDetail';
import { RecommendationsHolder, RecommendationsSection } from './SideContentBlock.style';
import { CarCountText, RandomCarTopWrapper, SideBlock, SideBlockHideButton } from './style';
import arrowRightIcon from '/public/icons/arrowRight.svg';
import { trackItemWebView } from '../../util/analytics';

interface SideContentBlockProps {
  itemId: number;
  items?: IItem.Item[];
  biddingComponent: React.ReactNode;
  chosenLiveAuction?: IItem.Item | null;
  isFromCarDetailPage?: boolean;
  isFromLiveAuctionPage?: boolean;
  isLiveAuctionWaiting?: boolean;
  openBidHistory?: () => void;
  handleChooseAuctionItem?: (item: IItem.Item) => void;
}

const SideContentBlock: FC<SideContentBlockProps> = ({
  itemId,
  items,
  biddingComponent,
  chosenLiveAuction = null,
  isFromCarDetailPage = false,
  isFromLiveAuctionPage = false,
  isLiveAuctionWaiting,
  openBidHistory,
  handleChooseAuctionItem,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const account = UserHooks.useUserAccount();
  const recommendations = ItemHooks.useRecommendations();
  const item = ItemHooks.useItem(itemId);

  const { isTablet } = useScreenSize();

  const [isHiddenSideBlock, setIsHiddenSideBlock] = useState(false);

  const now = AppHooks.useApplicationTimer();

  // TODO: We should look into fixing item.bidding.isRunning
  const isEbidRunning =
    item?.isEbid &&
    item?.bidding &&
    isEbidAuctionRunning(item.bidding?.startTime, item.bidding?.endTime, now);

  useEffect(() => {
    dispatch(userActions.getUserAccount());
    dispatch(itemActions.getRecommendationsList(1));
  }, [dispatch]);

  if (!item) {
    return null;
  }

  const renderSuggestedCars = () => {
    if (items?.length) {
      return items.map((item) => {
        return (
          <SuggestedCarsItem
            key={item.id}
            item={item}
            isFromLiveAuction={item.isLive}
            handleClickEvent={trackItemEvent}
            onItemClick={handleChooseAuctionItem}
          />
        );
      });
    }

    return recommendations.map((car) => {
      return <SuggestedCarsItem key={car.id} item={car} isFromLiveAuction={false} handleClickEvent={trackItemEvent}/>;
    });
  };

  const trackItemEvent = (item: IItem.Item) => {
    trackItemWebView(item, account);
  };

  // const auctionLotsLength = auctionLots?.length || 0;
  const isOpenedLiveAuctionModal = !!chosenLiveAuction && isFromLiveAuctionPage;

  return (
    <>
      {isTablet && isFromLiveAuctionPage && (
        <SideBlockHideButton>
          <Image
            src={arrowRightIcon}
            width="28.5px"
            height="18px"
            alt="arrow_right"
            onClick={() => setIsHiddenSideBlock(!isHiddenSideBlock)}
          />
        </SideBlockHideButton>
      )}
      <SideBlock
        id="side-content-block"
        isHidden={
          (isTablet && isHiddenSideBlock) ||
          isOpenedLiveAuctionModal ||
          (isTablet && isFromCarDetailPage)
        }
      >
        {isLiveAuctionWaiting ? (
          biddingComponent
        ) : (
          <>
            {/* We always have ShortDetail for all item */}
            <ShortDetail account={account} item={item} openBidHistory={openBidHistory} />
            {biddingComponent}
            {!item.purchase && (
              <RecommendationsSection>
                <RandomCarTopWrapper>
                  <CarCountText>
                    {item.isLive
                      ? `${t('titles:vehicle_list')}`
                      : `${t('labels:recommended_auctions')}`}
                  </CarCountText>
                  {/* {!item.isLive && <ViewAllText>View All</ViewAllText>} */}
                </RandomCarTopWrapper>
                <RecommendationsHolder>{renderSuggestedCars()}</RecommendationsHolder>
              </RecommendationsSection>
            )}
          </>
        )}
      </SideBlock>
    </>
  );
};

export default SideContentBlock;
